@import "../../assets/styles/colors";

.ours-packages {
  position: relative;
  margin: 4rem 0;

  h3 {
    font-weight: bold;

    &.title {
      color: $textColorGray;
      padding: 30px 0;
      font-size: 1.25rem;
    }

    span {
      color: $green;
    }
  }

  .packages-container {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .card {
    min-width: 250px;
    max-width: 350px;
    margin: 15px;
    border: none;

    .card-body {
      padding: 5px;
    }

    &.individual {
      .card-title {
        border-top: 15px solid $mainColor;
      }
    }
    &.familiar {
      .card-title {
        border-top: 15px solid $green;
      }
    }

    .card-title {
      font-weight: normal;
      font-size: 19px;
      padding: 13px 20px 0px 20px;
      margin-bottom: 0;

      svg {
        position: relative;
        top: 4px;
      }

      .icon {
        margin-bottom: 5px;
      }
    }

    .container-price {
      font-weight: bold;
      padding: 0px 20px 13px 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px !important;

      p {
        margin: 0;
      }

      span {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .classe {
      color: $mainColor;
      font-weight: bold;
      font-size: 26px;
    }

    .card-description {
      li {
        padding: 15px 0;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
