.register {
  background-color: #f8f8f8;

  .title {
    text-transform: capitalize;
  }

  .btn-green {
    min-width: 150px;
  }

  .disabled-btn {
    background-color: #adb5bd;
    cursor: not-allowed;
  }
  
}
