@import "./assets/styles/default";
@import "~@fortawesome/fontawesome-free/css/all.css";

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input[type="radio"] {
  cursor: pointer;
}
