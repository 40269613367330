@import "./_colors.scss";

img {
  max-width: 100%;
}

.f-text {
  font-size: 16px;
}

.f-title {
  font-size: 2rem;
  font-family: Arial, sans-serif;
  font-weight: 300;
  line-height: normal;

  @media (max-width: 560px) {
    font-size: 22px;
  }
}

.f-subtitle {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  font-weight: 300;
  line-height: normal;
  padding-top: 20px;
  max-width: 60%;

  @media (max-width: 560px) {
    font-size: 18px;
    max-width: none;
  }
}

ul li {
  list-style: none;
}

p,
span {
  color: $textColorGray;
}

a.nav-link:not(.btn-green) {
  color: $mainColor;
  font-weight: bold;
  position: relative;
  width: 100%;
  min-width: fit-content;

  &.active,
  &:hover {
    color: $mainColor;

    &:after {
      content: "";
      height: 3px;
      position: absolute;
      width: calc(100% - 40px);
      display: block;
      bottom: -3px;
      left: 20px;
      background-color: $mainColor;
    }
  }
}

.bg-green {
  background-color: $green;
}

.green-color {
  color: $green;
}

.white-color {
  color: #ffffff;
}

button,
a {
  &.btn-green {
    color: #fff;
    background-color: $green;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s;
    text-decoration: none;
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    text-align: center;

    &:not(.assign) {
      display: block;
    }

    @media (max-width: 320px) {
      max-width: none;
    }

    &:hover,
    &:focus {
      background-color: $greenHover;
      color: #fff !important;
    }
  }

  &.btn-green-hover-blue {
    color: #fff;
    background-color: $green;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    font-weight: bold;
    transition: color 0.3s;
    text-decoration: none;

    &:hover {
      color: $mainColor;
    }
  }

  &.btn-red {
    color: #fff;
    background-color: $red;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 2rem;
    font-weight: bold;
    transition: background-color 0.3s;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $redHover;
      color: #fff !important;
    }
  }

  &.btn-red-hover-blue {
    color: #fff;
    background-color: $red;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    font-weight: bold;
    transition: color 0.3s;
    text-decoration: none;

    &:hover {
      color: $mainColor;
    }
  }

  &:disabled {
    background-color: #adb5bd;
    cursor: no-drop;

    &:hover {
      background-color: #adb5bd;
    }
  }
}

.main-color,
.bg-main-color {
  background-color: $mainColor;
}

.overlay-logo {
  background-image: url("../../assets/img/new/background-overlay.png");
  background-position: center;
  background-size: cover;
  position: fixed;
  z-index: -2;
  height: 100vh;
  width: 100vw;
  top: 0;
  filter: opacity(0.3);
}

b {
  font-weight: bold !important;
}
