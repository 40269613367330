@import "../../assets/styles/colors";

.parceiros {
  min-height: 600px;

  @media (max-width: 900px) {
    min-height: 900px;
  }

  .container {
    display: flex;
    min-height: 450px;
    padding-top: 60px;
    padding-bottom: 40px;

    > div {
      flex: 1;

      &.image-container {
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;

        img {
          bottom: 0;
          left: -50px;
          position: absolute;
          max-width: 110%;

          @media (max-width: 1200px) {
            left: 0px;
          }

          @media (max-width: 900px) {
            max-width: 100%;
            position: unset;
          }
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      max-width: none;
    }

    @media (max-width: 600px) {
      max-width: none;
    }

    .text {
      min-width: 350px;
      margin-left: 100px;

      b {
        color: $mainColor;
      }

      .f-subtitle {
        max-width: none;
      }

      @media (max-width: 900px) {
        margin-left: 0px;
      }

      ul {
        display: inline-flex;
        flex-direction: column;
        padding: 0;

        li {
          border: 1px solid #ffffff;
          border-radius: 4px;
          margin: 3px 0;
          padding: 3px 20px 2px 2px;
          justify-self: center;

          img {
            padding-right: 5px;
          }
        }
      }

      .btn-green {
        margin-bottom: 30px;
      }

      @media (max-width: 1200px) {
        min-width: auto;

        p,
        h3 {
          font-size: 1rem;
        }
      }

      > h3 {
        font-size: 1rem;
      }

      p > h3 {
        color: $mainColor;
        font-weight: bold;
        display: inline;
      }
    }

    .carousel.slide {
      .carousel-item {
        text-align: center;

        p {
          background-color: #2a6dd3;
          border-radius: 5px;
          color: #ffffff;
          max-width: 70%;
          margin: 20px auto 30px auto;
        }

        &.saude .image-container img {
          max-width: 160px;
        }

        .image-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0 60px;
          max-height: 500px;

          img {
            display: flex;
            width: 100%;
            object-fit: contain;
            max-width: 200px;
            max-height: 120px;
            padding: 10px;
          }

          .muito-mais {
            flex: 1;
            display: flex;
            font-size: 24px;
            font-weight: bold;
            height: 160px;
            width: 160px;
            max-width: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .carousel-control-prev {
        left: -15px;
        > svg {
          position: absolute;
          top: 200px;
        }
      }
      .carousel-control-next {
        right: -10px;
        > svg {
          position: absolute;
          top: 200px;
        }
      }
    }

    .mobile {
      display: none;
    }

    @media (max-width: 900px) {
      margin-left: 0px;
      .desktop {
        display: none;
      }

      .mobile {
        display: block;
        padding: 20px 0;
      }
    }
  }
}
