@import "../../assets/styles/colors";

.navbar {
  background-color: #ffffff;

  a {
    margin: 10px;
  }

  .btn-whats {
    border: none;
    background-color: transparent;
    color: $mainColor;
    transition: color 0.15s;
    color: $mainColor;
    font-weight: bold;
  }

  .btn-green {
    padding: 0.6rem 2rem !important;
    min-width: fit-content;

    &.nav-link {
      font-weight: bold;
    }
  }

  .btn-order-now {
    color: #fff !important;
    padding: 0.6rem 2rem !important;
    border-radius: 10px;
    background-color: #dc8203;

    &:hover {
      background-color: $greenHover;
    }

    &:after {
      content: none !important;
    }
  }
}
