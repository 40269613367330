@import "../../assets/styles/colors";

.benefits {
  padding-top: 4rem;
  padding-bottom: 3rem;

  .container {
    display: flex;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .benefits-text {
      min-width: 350px;

      @media (max-width: 1200px) {
        min-width: auto;

        p,
        h3,
        span {
          font-size: 1.5rem;
        }
      }

      > h3 {
        font-size: 1rem;
      }

      p > span {
        font-weight: bold;
      }

      p > h3 {
        color: $mainColor;
        font-weight: bold;
        display: inline;
      }
    }

    .btn-green {
      margin: 20px 0;

      &.desktop {
        @media (max-width: 800px) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @media (max-width: 800px) {
          display: block;
        }
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 240px 240px 240px;
      gap: 15px;

      @media (min-width: 801px) and (max-width: 1200px) {
        grid-template-columns: auto auto;
      }

      @media (max-width: 800px) {
        grid-template-columns: auto;
      }

      .grid-component {
        background-color: #ffffff;
        padding: 20px 35px;
        border: 1px solid #ccc;
        border-radius: 6px;
      }
    }
  }
}
