$gray: #333;
$textColorGray: #242424;
$textLightGray: #8a8a8a;
$green: #30c342;
$red: #c33030;
$greenHover: #30b23f;
$redHover: #ac2b2b;
$mainColor: #022f74;
$bgGray: #F8F8F8;

:root {
  --gray: $gray;
  --green: $green;
  --greenHover: $greenHover;
  --red: $red;
  --redHover: $redHover;
  --mainColor: $mainColor;
  --textColorGray: $textColorGray;
  --bgGray: $bgGray;
  --textLightGray: $textLightGray;
}
