@import "../../assets/styles/colors";

.save-money {
    overflow: auto;

  .container-fluid {
    max-width: 1200px;
    display: flex;

    .row {
      justify-content: center;
      flex: 1;
    }
  }

  .table {
    padding: 0;

    p,
    h4 {
      margin: 0;
    }

    > div {
      border-top-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &.green p,
    &.green b {
      background-color: #b7ffcb;
    //   padding: 5px;


    //   font-size: 14px;
    }

    &.gray p,
    &.gray b {
      background-color: #e6e6e6;
    }

    &.blue p,
    &.blue b {
      background-color: #6cabff;
    }

    p {
      padding: 10px;
      border-bottom: 1px solid #ccc;

      &:nth-child(1) {
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom: none;
      }
    }

    h4 {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      max-width: fit-content;
      padding: 13px 15px 5px 15px;
      color: #ffffff;
      font-weight: bold;
      font-size: 1.25rem;
      min-height: 55px;
    }
  }

  @media (max-width: 1000px) {
    .container-fluid {
      min-width: 700px;
      overflow-x: scroll;

    //   .row {
    //     .col-3,
    //     .col-4 {
    //       padding: 0;
    //     }
    //   }
    }
  }
}
