@import "../../assets/styles/colors";

.resume {
  background-color: #f8f8f8;
  min-height: 100vh;

  .btn-green {
    min-width: 200px;
  }

  p {
    margin: 2px;
  }

  .row {
    padding-bottom: 20px;
  }

  .content {
    padding-left: 30px;
  }
}
