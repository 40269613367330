@import "../../assets/styles/colors";

.footer {
  background-color: #1e1e1e;

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    span {
      color: #999999;
    }

    .redes-sociais {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;

      a {
        width: 35px;
        height: 35px;
        padding: 5px;
        margin: 0 5px;
        display: flex;
        background-color: #444444;
        border-radius: 51%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
