@import "../../assets/styles/colors";

.contato {
  .container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .text {
      max-width: 500px;

      @media (max-width: 1200px) {
        min-width: auto;
      }

      .f-subtitle {
        max-width: none;
        line-height: 35px;
      }

      .f-title {
        font-weight: bold;
        color: $greenHover;

        @media (max-width: 560px) {
          font-size: 1.75rem;
        }

        span {
          font-size: inherit;
          font-weight: inherit;
          color: $mainColor;
        }
      }

      .btn-whats {
        display: flex;
        align-items: center;

        img {
          position: relative;
          z-index: 1;
        }

        button {
          background-color: #ffffff;
          border-color: $green;
          transition: background-color 0.5s;
          transition: color 0.5s;
          color: $green;
          height: 50px;
          min-width: 300px;
          position: relative;
          right: 15px;
          border-radius: 10px;
          font-weight: bold;

          @media (max-width: 800px) {
            min-width: calc(100% - 70px);
          }

          &:hover,
          &:focus {
            background-color: $greenHover;
            color: #ffffff;
          }
        }
      }

      .mini-font {
        font-size: 11px;
        font-weight: bold;
        display: flex;
      }
    }

    .image-container {
      display: flex;
      align-items: center;
    }
  }
}
