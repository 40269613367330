@import "../../assets/styles/colors";

.card-explain {
  background-image: url("../../assets/img/new/background.png");
  background-position: center top;
  background-size: cover;
  min-height: 600px;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 900px) {
    background-image: url("../../assets/img/new/background.png");
    background-position: right center;
    background-size: cover;
  }

  @media (max-width: 900px) {
    padding-bottom: 0em;
  }

  .container {
    z-index: 1;
    position: relative;

    .home-text {
      max-width: 60%;
      padding-top: 2em;

      @media (max-width: 900px) {
        max-width: none;
      }

      .sign-now-container {
        margin-top: 25px;
        display: flex;

        @media (max-width: 560px) {
          flex-direction: column-reverse;
        }

        .btn-sign-now {
          display: flex;
          background-color: #dc8203;
          transition: background-color 0.7s;
          color: #ffffff;
          border-radius: 4px;
          min-width: 270px;
          min-height: 60px;
          font-size: 22px;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          align-self: start;
          border: none;

          &:hover,
          &:focus {
            background-color: $greenHover;
          }

          @media (max-width: 560px) {
            width: 100%;
            min-width: auto;
            margin-top: 30px;
          }
        }

        .background-credit-card {
          padding: 13px;
          background: #00000069;
          border-radius: 50%;
          display: flex;
          align-self: flex-start;
          margin-right: 20px;
          margin-top: 10px;

          @media (max-width: 560px) {
            align-self: center;
          }
        }

        .pricing-container {
          color: #ffffff;
          p {
            color: #ffffff;
          }
        }
      }
    }
  }

  .background-images {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    .background-woman {
      display: flex;
      justify-content: center;

      @media (max-width: 900px) {
        display: none;
      }

      div {
        position: absolute;
        bottom: 0;
        width: 1200px;
        max-width: 100%;

        img {
          position: absolute;
          right: 94px;
          bottom: 0;

          @media (max-width: 1200px) {
            right: 0px;
          }
        }
      }
    }

    .background-phone {
      display: flex;
      justify-content: center;

      @media (max-width: 900px) {
        display: none;
      }

      div {
        position: absolute;
        bottom: 0;
        width: 1200px;
        max-width: 100%;

        img {
          position: absolute;
          right: 50px;
          bottom: -60px;

          @media (max-width: 1200px) {
            right: 0px;
          }
        }
      }
    }
  }

  .background-mobile {
    display: none;

    @media (max-width: 900px) {
      display: flex;
      justify-content: center;
    }
  }

  .anchor {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-bottom: 15px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .f-title {
    color: #ffffff;
    margin: 0;
    font-size: 1.5rem;

    span {
      color: $green;
    }
  }

  .f-subtitle {
    color: #ffffff;
    margin: 0;

    span {
      color: $green;
    }
  }
}
