@import "../../assets/styles/colors";

.diferenciais {
  background-color: #e7e7e79c;

  .container {
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: 800px) {
      flex-direction: column-reverse;

      .image-container {
        margin-top: 40px;

        img {
          border-radius: 30px;
        }
      }
    }

    .text {
      max-width: 450px;

      @media (max-width: 1200px) {
        min-width: auto;

        h3 {
          font-size: 1rem;
        }
      }

      h3 {
        font-size: 1rem;
        margin: 0;
      }

      .f-title {
        font-weight: bold;

        span {
          font-size: inherit;
          font-weight: inherit;
          color: $greenHover;
        }
      }

      ul {
        padding: 0;

        li {
          .f-subtitle {
            max-width: none;
            color: $greenHover;
            font-weight: bold;
          }

          span {
            font-size: 1.25rem;
          }
        }
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      background: #bdd4ea;
      border-radius: 30px;
      align-items: flex-end;
      position: relative;
      margin-top: 70px;

      img.heart {
        position: absolute;
        padding: 15px;
        top: -25px;
        left: 40px;
        max-width: 70px;
        background: #fff;
        border-radius: 51%;
      }
    }
  }
}
