@import "../../assets/styles/colors";

.vantagens {
  background-image: url("../../assets/img/new/background-mulheres.png");
  background-position: center;
  background-size: cover;

  .container {
    display: flex;
    min-height: 450px;
    padding-top: 30px;

    > div {
      flex: 1;

      &.image-container {
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;

        img {
          bottom: 0;
          left: -50px;
          position: absolute;
          max-width: 110%;

          @media (max-width: 1200px) {
            left: 0px;
          }

          @media (max-width: 900px) {
            max-width: 100%;
            position: unset;
          }
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .text {
      min-width: 350px;
      margin-left: 100px;

      @media (max-width: 900px) {
        margin-left: 0px;
      }

      .f-subtitle {
        padding-top: 0px;
      }

      p,
      h3,
      li {
        color: #ffffff;
      }

      ul {
        display: inline-flex;
        flex-direction: column;
        padding: 0;

        li {
          border: 1px solid #ffffff;
          border-radius: 4px;
          margin: 3px 0;
          padding: 3px 20px 2px 2px;
          justify-self: center;

          img {
            padding-right: 5px;
          }
        }
      }

      .btn-green {
        margin-bottom: 30px;
      }

      @media (max-width: 1200px) {
        min-width: auto;

        p,
        h3 {
          font-size: 1rem;
        }
      }

      > h3 {
        font-size: 1rem;
      }

      p > h3 {
        color: $mainColor;
        font-weight: bold;
        display: inline;
      }

      .f-subtitle {
        max-width: none;
      }
    }
  }
}
