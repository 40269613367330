@import "../../assets/styles/colors";

.como-funciona {
  .container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .text {
      @media (max-width: 1200px) {
        min-width: auto;

        h3 {
          font-size: 1rem;
        }
      }

      h3 {
        font-size: 1rem;
        margin: 0;
      }

      .f-title {
        font-weight: bold;

        span {
          font-size: inherit;
          font-weight: inherit;
          color: $greenHover;
        }
      }

      ul {
        max-width: 400px;
        padding: 0;

        li {
          margin-bottom: 20px;
          padding-bottom: 15px;
          border-bottom: 2px solid #ccc;

          .f-subtitle {
            margin: 0;
            font-weight: bold;
            color: $mainColor;
            padding-bottom: 10px;
          }

          span {
            font-size: 1.1rem;
          }
        }
      }
    }

    .image-container {
      display: flex;
      align-items: center;
    }
  }
}
