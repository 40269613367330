@import "../../assets/styles/colors";

.register {
  background-color: #f8f8f8;

  .title {
    text-transform: capitalize;

    span {
      color: $green;
    }
  }

  .btn-green {
    min-width: 200px;
  }

  .input-cep {
    display: flex;
    position: relative;

    .container-loading {
      visibility: hidden;
      background: #ffffff;
      width: 25px;
      border: none;
      position: absolute;
      right: 25px;
      bottom: 7px;
      padding: 3px;

      &.loading-active {
        visibility: visible;
      }
    }
  }
}
