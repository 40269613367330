@import "../../assets/styles/colors";

.consultas {
  background-color: #a0d5ff75;

  .container {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;

    > div {
      flex: 1;

      &.image-container {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column-reverse;
    }

    .text {
      min-width: 350px;

      @media (max-width: 1200px) {
        min-width: auto;

        p,
        h3,
        span {
          font-size: 1rem;
        }
      }

      > h3 {
        font-size: 1rem;
      }

      p > span {
        font-weight: bold;
      }

      p > h3 {
        color: $mainColor;
        font-weight: bold;
        display: inline;
      }

      .f-subtitle {
        max-width: none;
      }
    }

    .btn-credenciados {
      display: flex;
      background-color: #dc8203;
      transition: background-color 0.7s;
      color: #ffffff;
      border-radius: 4px;
      min-width: 270px;
      min-height: 45px;
      font-size: 1rem;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      align-self: start;
      border: none;
      width: fit-content;
      padding: 0 20px;
      font-weight: bold;

      &:hover,
      &:focus {
        background-color: $greenHover;
      }

      &.mobile {
        display: none;
      }

      @media (max-width: 560px) {
        width: 100%;
        min-width: auto;
        margin-top: 30px;

        &.mobile {
          display: flex;
          order: -1;
          padding: 0 10px;
          font-size: 0.9rem;
        }

        &.desktop {
          display: none;
        }
      }
    }
  }
}
